import { cls } from '@/utils'
import { Creation, JobStatus } from '@/types'
import Button from '@/components/button'
import { MouseEventHandler } from 'react'
import IconError from '@haiper/icons-svg/icons/outline/x-circle.svg'

interface ErrorJobProps {
  handleRetry: MouseEventHandler<HTMLButtonElement>
  jobStatus: JobStatus | null
  creation: Creation
  showMode: 'mobile' | 'grid' | 'single'
}

const disableRetry = true

const ErrorJob = ({ handleRetry, jobStatus, creation, showMode }: ErrorJobProps) => {
  const isTemplateGeneration = creation?.type?.startsWith('template')
  return (
    <div
      className={cls(
        'flex justify-center items-center flex-col bg-surface w-full border border-solid border-border rounded-lg aspect-video gap-2 @md:gap-3',
      )}
    >
      <IconError className={cls('text-icon-subdued', showMode === 'single' ? 'size-12' : 'size-12')} />
      <div className='w-full flex justify-center items-center'>Failed to generate</div>
      <Button
        variant='outline'
        size={showMode === 'single' ? 'default' : 'sm'}
        className={cls(
          'min-w-[70px] md:min-w-[70px]',
          showMode === 'single' ? 'h-10' : 'h-8',
          isTemplateGeneration && 'hidden',
          disableRetry && 'hidden',
        )}
        onClick={handleRetry}
      >
        Retry
      </Button>
    </div>
  )
}

export default ErrorJob
